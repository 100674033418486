import React from "react"
import { Link } from "gatsby"
import { useCart } from '../hooks/useCart';
import ReedmakerConfig from "../../content/reedmaker.yaml"
import CaptchaLegal from "./CaptchaLegal"

const CallToActionButton = () => {
  const { isEmpty } = useCart();
  const link = isEmpty ? '/shop/' : '/cart/'
  const text = isEmpty ? 'Shop' : 'Checkout'

  return (
    <>
      <Link to={link} className="block px-2 py-2 mt-2 mx-8 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-red-900 focus:shadow-outline-indigo transition duration-150 ease-in-out">
        {text}
      </Link>
    </>
  )   
}

const Footer = () => {
  const { content } = ReedmakerConfig

  return (
    <section>
      <footer className="text-gray-700 bg-gray-100 border-t body-font">
        <div
          className="container flex flex-col flex-wrap p-5 mx-auto md:items-center lg:items-start md:flex-row md:flex-no-wrap ">
          <div className="flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left">
            <p className="mt-6 text-xs text-center text-gray-600">
              All my Reeds are totally Guaranteed! <br/>There is No Risk!
              <CallToActionButton />
            </p>
          </div>
          <div className="flex flex-wrap flex-grow mt-10 -mb-10 text-center md:pl-20 md:mt-0 md:text-left ">
            <div className="w-full px-4 lg:w-1/3 md:w-1/2">
              <h1 className="mb-3 text-sm font-medium tracking-widest text-red-800 uppercase title-font">Product
                </h1>
              <nav className="mb-10 list-none">
                <li>
                  <Link to="/shop/oboe-reeds" className="text-sm text-gray-600 hover:text-gray-800">Oboe Reeds</Link>
                </li>
                <li>
                  <Link to="/shop/english-horn-reeds" className="text-sm text-gray-600 hover:text-gray-800">English Horn Reeds</Link>
                </li>
                <li>
                  <Link to="/bassoon-shop" className="text-sm text-gray-600 hover:text-gray-800">Bassoon Reeds</Link>
                </li>
                <li>
                  <Link to="/shop/reed-cases" className="text-sm text-gray-600 hover:text-gray-800">Reed Cases</Link>
                </li>
                <li>
                  <Link to="/shop/other" className="text-sm text-gray-600 hover:text-gray-800">Other</Link>
                </li>
              </nav>
            </div>
            <div className="w-full px-4 lg:w-1/3 md:w-1/2">
              <h1 className="mb-3 text-sm font-medium tracking-widest text-red-800 uppercase title-font">
                Reedmaker Site
              </h1>
              <nav className="mb-10 list-none">
                <li>
                  <Link to="/shop/" className="text-sm text-gray-600 hover:text-gray-800">Shop</Link>
                </li>
                <li>
                  <Link to="/learn-more/" className="text-sm text-gray-600 hover:text-gray-800">Learn</Link>
                </li>
                <li>
                  <Link to="/testimonials/" className="text-sm text-gray-600 hover:text-gray-800">Testimonials</Link>
                </li>
                <li>
                  <Link to="/contact-me/" className="text-sm text-gray-600 hover:text-gray-800">Contact Me</Link>
                </li>
              </nav>
            </div>
            <div className="w-full px-4 lg:w-1/3 md:w-1/2">
              <h1 className="mb-3 text-sm font-semibold tracking-widest text-red-800 uppercase title-font">
                Contact
                </h1>
              <nav className="mb-10 list-none">
                <li>
                  <Link to="/contact-me/" className="text-sm text-gray-600 hover:text-gray-800">Contact Form</Link>
                </li>
                <li>
                  <a href="mailto:kerry@reedmaker.com" className="text-sm text-gray-600 hover:text-gray-800">
                    Kerry@Reedmaker.com
                  </a>
                </li>
                <li>
                  <a href="tel:+14102683996" className="text-sm text-gray-600 hover:text-gray-800">+1 410.268.3996</a>
                </li>
              </nav>
            </div>
          </div>
        </div>
        <div className="bg-blueGray-900">
          <div className="container flex flex-col flex-wrap px-5 py-6 mx-auto sm:flex-row">
            <p className="text-sm text-center text-black sm:text-left ">
              © {new Date().getFullYear()} {content.footer.company} 
              <br /> <CaptchaLegal />
              <br /> <span className="text-xs text-gray-600 italic">Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></span>
            </p>
          </div>
        </div>
      </footer>
    </section>
  )
};

export default Footer
